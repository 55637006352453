// @flow strict
import React from 'react';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';
import { Link } from 'gatsby';

const Author = () => {
  const { author } = useSiteMetadata();
  
  return (
    <div className={styles['author']}>
      <Link to = "/"><strong>{author.name}</strong></Link>
      &nbsp;&bull;&nbsp;
      <span className={styles['author__bio']} dangerouslySetInnerHTML={{ __html: author.bio }} />
    </div>
  );
};

export default Author;
